import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(
    public datepipe: DatePipe,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {}
  baseUploadServiceUrl = environment.apiUrl + environment.uploadApi;

  setToJsonDateOnly(dateValue: Date) {
    return this.datepipe.transform(dateValue, 'yyyy-MM-dd');
  }

  setDateFormat(dateValue: Date, strFormat: string) {
    return this.datepipe.transform(dateValue, strFormat);
  }

  setToJsonDateTime(dateValue: any) {
    return this.datepipe.transform(dateValue, 'yyyy-MM-ddTHH:mm:ss');
  }
  truncateTime(date: Date) {
    if (date !== null) {
      date.setHours(0, 0, 0, 0);
    }
    return date;
  }
  compareDate(dateType: String, startDate: Date, endDate: Date) {
    if (!startDate || !endDate) {
      return false;
    }
    if (dateType === 'startValue') {
      return startDate > endDate;
    } else {
      return endDate < startDate;
    }
  }

  setToJsonDateTimeUTC(dateValue: any) {
    var now_utc = Date.UTC(
      dateValue.getUTCFullYear(),
      dateValue.getUTCMonth(),
      dateValue.getUTCDate(),
      dateValue.getUTCHours(),
      dateValue.getUTCMinutes(),
      0
    );

    //return this.datepipe.transform(now_utc, 'yyyy-MM-ddTHH:mm:ss');

    return this.datepipe.transform(now_utc, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
  }

  removeTime(date: any) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  setToJsonDateOnlyUTC(dateValue: any) {
    var now_utc = Date.UTC(
      dateValue.getUTCFullYear(),
      dateValue.getUTCMonth(),
      dateValue.getUTCDate(),
      0,
      0,
      0
    );

    //return this.datepipe.transform(now_utc, 'yyyy-MM-ddTHH:mm:ss');

    return this.datepipe.transform(now_utc, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
  }

  setToDate(dateString: string) {
    const [day, month, year] = dateString.split('/');

    const dateObj = new Date(+year, +month - 1, +day);

    return dateObj;
  }

  getIPAddress() {
    return this.http.get('http://api.ipify.org/?format=json');
  }

  GetUploadUrl(uploadType: string) {
    return decodeURI(`${this.baseUploadServiceUrl}/UploadFile/${uploadType}`);
  }

  currencyToNumber(currency: string) {
    return Number(currency.replace(/[^0-9.-]+/g, ''));
  }

  getCardImg(tier: string) {
    if (tier) {
      if (tier.toUpperCase() === 'PREFERRED') {
        return 'assets/images/cards/preferred.png';
      }

      if (tier.toUpperCase() === 'PRIME') {
        return 'assets/images/cards/prime.png';
      }

      if (tier.toUpperCase() === 'PRESTIGE') {
        return 'assets/images/cards/prestige.png';
      }

      if (tier.toUpperCase() === 'PREMIER') {
        return 'assets/images/cards/premier.png';
      }
    }

    return 'assets/images/cards/primary.png';
  }

  bytesToMB(fileSizeInBytes: number): string {
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    return `${Math.round(fileSizeInMB * 100) / 100} MB`;
  }

  bytesToKB(fileSizeInBytes: number): string {
    const fileSizeInKB = fileSizeInBytes / 1024;
    return `${Math.round(fileSizeInKB * 100) / 100} KB`;
  }

  bytesConvetorDisplay(bytes: number): string {
    return bytes > 100000 ? this.bytesToMB(bytes) : this.bytesToKB(bytes);
  }

  remarkInvoiceText: string = `1. Payment Term : Deposit 50%, 20% after install solar mounting and PV Panel, 20% after install Inverter and Battery system and 10% หลังทดสอบจ่ายไฟฟ้าเข้าระบบและมอบหมายงาน
2. Delivery Period : within 75 days after receipt of deposit.
3. Quality Warranty : Inverter warranty period is 10 years. PV Panel warranty period is 10 years product warranty / 25 years Linear power warranty as panel specification ,Installation warranty period is 2 years.
4. All bank charges and service fees shall be paid by Buyer
5. This quotation is confidential, please do not disclose any of it to any third party by any manner.
6. This quotaion is on 1 USD = 35.75 THB , the price may change if the exchange rate change mare than 3%
7. ฟรีค่าตรวจเช็คและล้างแผง 1 ครั้ง/ปี เป็นเวลา 2 ปี`;

  StatusSelect = [
    { label: 'Active', value: 'A' },
    { label: 'Inactive', value: 'I' },
  ];

  public b64toBlob(b64Data: string, contentType: string) {
    contentType = contentType || '';
    let sliceSize = 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  checkValidateForm(form: any): boolean {
    if (form.invalid) {
      for (const i in form.controls) {
        form.controls[i].markAsDirty();
        form.controls[i].updateValueAndValidity();
      }
      return false;
    } else {
      return true;
    }
  }
}
